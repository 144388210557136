//Приближение картинок
document.addEventListener("DOMContentLoaded", function (event) {
  const imgsToZoom = document.querySelectorAll(".zoom-in");
  const zoomInBlock = document.querySelector(".zoom-in-block");

  function zoomInImg(e) {
    const targetPicture = e.target.parentElement;

    if (targetPicture.tagName === "PICTURE") {
      const targetAvif = targetPicture.querySelector('source[type="image/avif"]');
      const targetWebp = targetPicture.querySelector('source[type="image/webp"]');
      const targetJpg = targetPicture.querySelector("img");

      let picture = zoomInBlock.querySelector(".zoom-in-block__img").parentElement;

      let avif;
      if (targetAvif !== null) {
        avif = picture.children[0];
        avif.setAttribute("srcset", targetAvif.getAttribute("srcset"));
      }

      let webp;
      if (targetWebp !== null) {
        webp = picture.children[1];
        webp.setAttribute("srcset", targetWebp.getAttribute("srcset"));
      }

      let jpg = picture.children[2];
      jpg.setAttribute("src", targetJpg.getAttribute("src"));
      jpg.setAttribute("srcset", targetJpg.getAttribute("srcset"));
      jpg.setAttribute("alt", targetJpg.getAttribute("alt"));

      const width = e.target.width;
      const height = e.target.height;

      if (width > height) {
        jpg.style.width = "80%";
        jpg.style.height = "auto";
      } else {
        jpg.style.width = "auto";
        jpg.style.height = "80%";
      }

      if (window.innerWidth < 800) {
        if (width > height) {
          jpg.style.width = "100%";
          jpg.style.height = "auto";
        } else {
          jpg.style.width = "auto";
          jpg.style.height = "80%";
        }
      }

      if (window.innerWidth < 500) {
        jpg.style.width = "100%";
        jpg.style.height = "auto";
      }

      gsap.to(zoomInBlock, {
        css: {
          opacity: "1",
          pointerEvents: "auto",
        },
      });
      document.body.style.overflowY = "hidden";
    }
  }

  function zoomOutImg(e) {
    gsap.to(zoomInBlock, {
      css: {
        opacity: "0",
        pointerEvents: "none",
      },
    });
    document.body.style.overflowY = "auto";
  }

  imgsToZoom.forEach((imgToZoom) => {
    imgToZoom.addEventListener("click", zoomInImg);
  });

  zoomInBlock.addEventListener("click", zoomOutImg);
});
